body {
  background-color: #19191d;
}

.App {
  font-family: sans-serif;
  text-align: center;
  font-size: 24px;
  color: #efcb7d;
}

.container {
  height: 374px;
  width: 320px;
  margin-bottom: 32px;
  /*position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto auto;*/
}
